<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {addCustomFont, addCustomImage, loadAssetsFonts, loadProject, useSession} from '/src/core/session';
import {toRef} from 'vue';
import {Project, ProjectScreen, getProjectWithScreens} from '/src/api/projects';
import router from '/src/router';
import ModalPricing from '/src/components/pricing/ModalPricing.vue';
import FuiEditor from '../fui/FuiEditor.vue';
import {getAssetsByProjectId} from '/src/api/assets';
import {loadImageAsync, logEvent} from '/src/utils';

const session = useSession();
const {state, setIsPublic} = session;
const {platform, auth, display} = toRefs(state);
const currenProject = ref({} as Project);
const currentScreen = ref({} as ProjectScreen);
const isScreenLoaded = ref(false);
const isSandboxModalOpen = ref(false);
const isPricesVisible = ref(false);
const isScreenNotFound = ref(false);
const isSandbox = ref(false);

const route = useRoute();

// computed
const project_id = computed(() => parseInt(route.params.project_id as string));
const screen_id = computed(() => parseInt(route.params.screen_id as string));

watch(
    () => route.params.screen_id,
    async (newScreenId) => {
        isScreenNotFound.value = false;
        isScreenLoaded.value = false;
        await openProject(project_id.value, parseInt(newScreenId as string));
        isScreenLoaded.value = true;
    }
);

const emit = defineEmits(['showModal']);

// methods

async function openProject(project_id, screen_id) {
    if (!project_id) {
        currenProject.value = {
            id: 0,
            title: '',
            screens: [{id: 0}],
            platform: platform.value,
            screen_x: display.value.x,
            screen_y: display.value.y,
            private: true,
        };
        return;
    }

    const projectData = await getProjectWithScreens(project_id);
    if (!projectData || projectData.user_id !== auth.value?.user?.id) {
        if (!auth.value) {
            router.push('/sandbox');
            session.initSandbox();
        } else {
            router.push('/');
        }
        return;
    }
    currenProject.value = projectData;
    let screen;
    if (!screen_id) {
        screen = projectData.screens[0];
        !!screen && router.push(`/editor/${project_id}/${screen.id}`);
    } else {
        screen = projectData.screens?.find((item) => item.id === screen_id);
    }
    if (!screen) {
        isScreenNotFound.value = true;
    }
    currentScreen.value = await loadProject(projectData, screen);
}

function showModalPricing() {
    isPricesVisible.value = true;
}

function closeModalPricing() {
    isPricesVisible.value = false;
}

function update() {
    openProject(project_id.value, screen_id.value);
}

function closeSandboxModal() {
    isSandboxModalOpen.value = false;
    localStorage.setItem('sandboxModalShown', 'true');
}

async function loadAssetsImages() {
    const assets = await getAssetsByProjectId(project_id.value, 'image');
    if (!assets) return;
    const assetPromises = assets.map(async (asset) => {
        await loadImageAsync(asset.url).then((image) => {
            const {width, height} = image;
            addCustomImage(asset.filename, width, height, image, false, asset.id);
        });
    });
    return Promise.all(assetPromises);
}

onMounted(async () => {
    session.state.customImages = [];
    session.state.customFonts = [];
    isScreenLoaded.value = false;
    if (router.currentRoute.value.path === '/sandbox') {
        isScreenLoaded.value = true;
        isSandbox.value = true;
        session.initSandbox();
        !auth.value &&
            setTimeout(() => {
                isSandboxModalOpen.value = true;
                logEvent('sandbox_modal_show');
            }, 7000);
    } else {
        await loadAssetsFonts(project_id.value);
        loadAssetsImages();
    }
    await openProject(project_id.value, screen_id.value);
    isScreenLoaded.value = true;
    setIsPublic(false);
});
</script>

<template>
    <div class="hidden md:block">
        <dialog
            id="sandbox_modal"
            class="modal modal-open"
            v-if="isSandboxModalOpen && isSandbox"
        >
            <div class="modal-box border border-primary -mt-32">
                <button
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    @click="closeSandboxModal"
                >
                    ✕
                </button>
                <!-- <h3 class="text-xl font-bold">Lopaka.app Editor</h3> -->
                <p class="pb-2 text-primary text-lg">Welcome to Lopaka Sandbox!</p>
                <p class="py-2">
                    It's recommended to
                    <b>Sign In</b>
                    to save your Projects for future use and unlock full access to Lopaka's features.
                    <span class="text-info">Otherwise you can lose your work.</span>
                </p>
                <p class="text-lg py-2">
                    Unleash your creativity with
                    <router-link
                        to="/price"
                        class="text-success"
                        @click="logEvent('sandbox_modal_price')"
                    >
                        Lopaka Plus
                    </router-link>
                </p>
                <ul class="list-disc list-inside text-lg pb-2">
                    <li>Unlimited Private Projects</li>
                    <li>Unlimited Private Screens</li>
                    <li>Custom Fonts Upload</li>
                    <li>Priority Support</li>
                </ul>
                <div class="text-center py-2">
                    <router-link
                        to="/login#buy"
                        class="btn btn-success text-lg"
                        @click="logEvent('sandbox_modal_plus')"
                    >
                        Get Plus for
                        <span class="">$5.95/mo</span>
                    </router-link>
                </div>
                <p class="text-center">
                    or
                    <router-link
                        to="/login"
                        class="text-success"
                        @click="logEvent('sandbox_modal_signin')"
                    >
                        proceed to Sign In
                    </router-link>
                </p>
            </div>
        </dialog>
        <FuiEditor
            :project="currenProject"
            :screen="currentScreen"
            :isScreenLoaded="isScreenLoaded"
            :isScreenNotFound="isScreenNotFound"
            @showModalPricing="showModalPricing"
            @update="update"
            :isSandbox="isSandbox"
        />
        <ModalPricing
            v-if="isPricesVisible"
            @close="closeModalPricing"
            canBeClosed
        />
    </div>
</template>
